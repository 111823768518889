"use client";

import { Box, Button, SpaceBetween } from "@cloudscape-design/components";
import Spinner from "@cloudscape-design/components/spinner";
import { useState } from "react";
import { invokeHealthCheck } from "../api/healthcheck";

const Home = () => {
  const [response, setResponse] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleHealthCheck = async () => {
    setIsLoading(true);
    const response = await invokeHealthCheck();
    setResponse(response);
    setIsLoading(false);
  };

  return (
    <SpaceBetween size="l" alignItems="center">
      <Box variant="h1">
        Welcome to the Former Employee Bindle Clean Up Tool
      </Box>
      <Box variant="p">
        This tool is used to clean up the former employee bindle.
      </Box>
      <Button variant="primary" onClick={handleHealthCheck}>
        Health Check Button
      </Button>

      <Box>
        {isLoading ? (
          <span>
            <Spinner size="large" />
          </span>
        ) : (
          response
        )}
      </Box>
    </SpaceBetween>
  );
};

export default Home;
