import { AppLayout, HelpPanel } from "@cloudscape-design/components";
import I18nProvider from "@cloudscape-design/components/i18n";
import messages from "@cloudscape-design/components/i18n/messages/all.en";
import { Outlet } from "react-router";
import { LOCALE } from "../../constants/appConstants";
import SideNav from "./SideNav";

const FebAppLayout = () => {
  return (
    <I18nProvider locale={LOCALE} messages={[messages]}>
      <AppLayout
        navigation={<SideNav />}
        tools={
          <HelpPanel
            header={<h2>Former Employee Resource Management (FEB TOOL)</h2>}
          >
            Help content
            {/* TODO: Add help content */}
          </HelpPanel>
        }
        content={<Outlet />}
      />
    </I18nProvider>
  );
};

export default FebAppLayout;
