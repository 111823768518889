import { SideNavigation } from "@cloudscape-design/components";

const SideNav = () => {
  return (
    <SideNavigation
      header={{
        href: "/",
        text: "Former Employee Resource Management",
      }}
      items={[
        { type: "link", text: "Home", href: "/" },
        {
          type: "link",
          text: "Cleanup Bindles",
          href: "/deletion-workflow",
        },
      ]}
    />
  );
};

export default SideNav;
