import { API_HEALTHCHECK_ENDPOINT } from "../constants/apiConstants";
import { HTTPStatus } from "../constants/appConstants";

export const invokeHealthCheck = async () => {
  try {
    const response = await fetch(API_HEALTHCHECK_ENDPOINT, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    });

    if (response.status === HTTPStatus.Success) {
      const data = await response.json();
      return data.message;
    } else {
      return `API returned ${response.status} status code`;
    }
  } catch (error) {
    console.log(error);
    console.log("An error occurred. Please try again later.");
    return `An error occurred. Please try again later.`;
  }
};
