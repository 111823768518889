"use client";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import FebAppLayout from "./components/Layouts/FebAppLayout";
import DeletionHandler from "./pages/DeletionWorkflow";
import Home from "./pages/Home";

export default function App() {
  return (
    <Router>
      <Routes>
        <Route element={<FebAppLayout />}>
          <Route index path="/" element={<Home />} />
          <Route path="deletion-workflow" element={<DeletionHandler />} />
        </Route>
      </Routes>
    </Router>
  );
}
