import { ContentLayout, Header, Link } from "@cloudscape-design/components";

const DeletionWorkflow = () => {
  return (
    <ContentLayout
      header={
        <Header variant="h1" info={<Link variant="info">Info</Link>}>
          Former Employee Bindle Clean Up Workflow
        </Header>
      }
    >
      {/* TODO: Stepper components goes here */}
    </ContentLayout>
  );
};

export default DeletionWorkflow;
